var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "categoryChildren bj" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [
          _c("el-button", {
            staticClass: "bjsearch",
            attrs: { type: "primary", icon: "el-icon-refresh" },
            on: { click: _vm.Refresh },
          }),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addChild } },
            [_vm._v("新增")]
          ),
          _c("el-button", { on: { click: _vm.gopath } }, [_vm._v("返回")]),
          _c(
            "div",
            { staticClass: "seach" },
            [
              _c(
                "div",
                { staticClass: "inputs" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "孝感发品是否需要审核",
                        clearable: "",
                      },
                      model: {
                        value: _vm.from.is_audit_xiao,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "is_audit_xiao", $$v)
                        },
                        expression: "from.is_audit_xiao",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "否", value: 0 } }),
                      _c("el-option", { attrs: { label: "是", value: 1 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "inputs" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "高碑店发品是否需要审核",
                        clearable: "",
                      },
                      model: {
                        value: _vm.from.is_audit_gao,
                        callback: function ($$v) {
                          _vm.$set(_vm.from, "is_audit_gao", $$v)
                        },
                        expression: "from.is_audit_gao",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "否", value: 0 } }),
                      _c("el-option", { attrs: { label: "是", value: 1 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "inputs" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入品类名称", clearable: "" },
                    model: {
                      value: _vm.from.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.from, "name", $$v)
                      },
                      expression: "from.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.search },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "auto-table-flex" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.listData,
                height: "100%",
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  width: "100px",
                  align: "center",
                  label: "ID",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  align: "center",
                  label: "名称",
                  width: "100px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$empty.empty(scope.row.name)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "freight_parameter",
                  align: "center",
                  label: "运费系数(%)",
                  width: "100px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.freight_parameter) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "purchase_parameter",
                  align: "center",
                  label: "服务费系数(%)",
                  width: "100px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.purchase_parameter) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "品牌/品种",
                  prop: "tag",
                  minWidth: "100px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "tag-wrap" }, [
                          _c(
                            "div",
                            { staticClass: "tag-box" },
                            _vm._l(scope.row.tags, function (child) {
                              return _c(
                                "span",
                                { key: child.id, staticClass: "tag" },
                                [_vm._v(_vm._s(child.name))]
                              )
                            }),
                            0
                          ),
                          _c("span", { staticClass: "icon" }, [
                            _c("i", {
                              staticClass: "el-icon-edit-outline",
                              on: {
                                click: function ($event) {
                                  return _vm.onHandleTag(scope.row)
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "排序",
                  prop: "listorder",
                  width: "100px",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "is_audit_gao",
                  align: "center",
                  width: "100px",
                  label: "高碑店集配发品是否需要审核",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.is_audit_gao == 1 ? "是" : "否") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "is_audit_xiao",
                  align: "center",
                  width: "100px",
                  label: "孝感集配发品是否需要审核",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.is_audit_xiao == 1 ? "是" : "否") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editChild(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onHandleQrCode(scope.row)
                              },
                            },
                          },
                          [_vm._v("小程序二维码")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("goods-tag-dialog", {
        ref: "refGoodsTagDialog",
        on: { "on-change": _vm.ajaxGetCategories },
      }),
      _vm.childDialogVisible
        ? _c(
            "el-dialog",
            {
              ref: "refChildCategoryDialog",
              staticClass: "dialog",
              attrs: {
                title: _vm.childTitle,
                visible: _vm.childDialogVisible,
                width: "70%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.childDialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "contents" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "childForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.childForm,
                        rules: _vm.childRules,
                        "label-width": "200px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "父级品类", prop: "pid" } },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    disabled: _vm.childDisabled,
                                    placeholder: "选择父级品类",
                                  },
                                  model: {
                                    value: _vm.childForm.pid,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.childForm, "pid", $$v)
                                    },
                                    expression: "childForm.pid",
                                  },
                                },
                                _vm._l(_vm.options, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "品类名称", prop: "name" } },
                        [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入品类名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.childForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.childForm, "name", $$v)
                                  },
                                  expression: "childForm.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "运费系数(%)",
                            prop: "freight_parameter",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "inputs" },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入运费",
                                  clearable: "",
                                },
                                on: { blur: _vm.freightBlur },
                                model: {
                                  value: _vm.childForm.freight_parameter,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.childForm,
                                      "freight_parameter",
                                      $$v
                                    )
                                  },
                                  expression: "childForm.freight_parameter",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "服务费系数(%)",
                            prop: "purchase_parameter",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "inputs" },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入服务费",
                                  clearable: "",
                                },
                                on: { blur: _vm.purchaseBlur },
                                model: {
                                  value: _vm.childForm.purchase_parameter,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.childForm,
                                      "purchase_parameter",
                                      $$v
                                    )
                                  },
                                  expression: "childForm.purchase_parameter",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序", prop: "listorder" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入排序值",
                              maxlength: "5",
                              clearable: "",
                            },
                            model: {
                              value: _vm.childForm.listorder,
                              callback: function ($$v) {
                                _vm.$set(_vm.childForm, "listorder", $$v)
                              },
                              expression: "childForm.listorder",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品详情默认图片",
                            prop: "detail_pic",
                          },
                        },
                        [
                          _c("b-file-upload", {
                            key: "detail_pic",
                            attrs: {
                              accepts: ["jpg", "jpeg", "png"],
                              limit: 1,
                            },
                            model: {
                              value: _vm.detailPicList,
                              callback: function ($$v) {
                                _vm.detailPicList = $$v
                              },
                              expression: "detailPicList",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品详情默认视频",
                            prop: "detail_video",
                          },
                        },
                        [
                          _c("b-file-upload", {
                            key: "detail_video",
                            attrs: {
                              accepts: ["mp4", "avi", "flv", "mov"],
                              limit: 1,
                            },
                            model: {
                              value: _vm.detailVideoList,
                              callback: function ($$v) {
                                _vm.detailVideoList = $$v
                              },
                              expression: "detailVideoList",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "禁售城市仓", prop: "logistic_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "logistic-select_wrap",
                              attrs: {
                                placeholder: "选择禁售城市仓",
                                loading: _vm.loadingCityStore,
                                multiple: "",
                                filterable: "",
                                clearable: "",
                              },
                              on: { change: _vm.onCityStore },
                              model: {
                                value: _vm.logisticIds,
                                callback: function ($$v) {
                                  _vm.logisticIds = $$v
                                },
                                expression: "logisticIds",
                              },
                            },
                            _vm._l(_vm.cityStoreList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.childForm.id && _vm.childForm.is_audit_gao == 1
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "选择招商", prop: "buyer_id" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "选择选择招商",
                                  },
                                  model: {
                                    value: _vm.childForm.buyer_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.childForm, "buyer_id", $$v)
                                    },
                                    expression: "childForm.buyer_id",
                                  },
                                },
                                _vm._l(_vm.updatecategorylist, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.fullname,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "高碑店集配发品是否需要审核",
                            prop: "is_audit_gao",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: {
                                disabled: _vm.is_audit_gao == 1 ? true : false,
                              },
                              on: { change: _vm.gaochang },
                              model: {
                                value: _vm.childForm.is_audit_gao,
                                callback: function ($$v) {
                                  _vm.$set(_vm.childForm, "is_audit_gao", $$v)
                                },
                                expression: "childForm.is_audit_gao",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("是"),
                              ]),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("否"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "孝感集配发品是否需要审核",
                            prop: "is_audit_xiao",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: {
                                disabled: _vm.is_audit_xiao == 1 ? true : false,
                              },
                              model: {
                                value: _vm.childForm.is_audit_xiao,
                                callback: function ($$v) {
                                  _vm.$set(_vm.childForm, "is_audit_xiao", $$v)
                                },
                                expression: "childForm.is_audit_xiao",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("是"),
                              ]),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("否"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.childDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.childConfirm },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }