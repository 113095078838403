<template>
  <div class="categoryChildren bj">
    <div class="title">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="bjsearch"
        @click="Refresh"
      ></el-button>

      <el-button type="primary" @click="addChild">新增</el-button>
      <el-button  @click="gopath">返回</el-button>
        <div class="seach">
        <div class="inputs">
         <el-select
              v-model="from.is_audit_xiao"
              placeholder="孝感发品是否需要审核"
              clearable
            >
              <el-option label="否" :value="0"></el-option>
              <el-option label="是" :value="1"></el-option>
            </el-select>
        </div>
        <div class="inputs">
         <el-select
              v-model="from.is_audit_gao"
              placeholder="高碑店发品是否需要审核"
              clearable
            >
            <el-option label="否" :value="0"></el-option>
            <el-option label="是" :value="1"></el-option>
            </el-select>
        </div>
        <div class="inputs">
          <el-input v-model="from.name" placeholder="请输入品类名称" clearable></el-input>
        </div>

        <el-button @click="search" type="primary" icon="el-icon-search">查询</el-button>
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="listData"
        v-loading="loading"
        height="100%"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        style="width: 100%"
      >
        <!-- <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column> -->
        <el-table-column prop="id" width="100px" align="center" label="ID">
        </el-table-column>
        <el-table-column prop="name" align="center" label="名称" width="100px">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.name) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="freight_parameter"
          align="center"
          label="运费系数(%)"
          width="100px"
        >
          <template slot-scope="scope">
            {{ scope.row.freight_parameter }}
          </template>
        </el-table-column>
        <el-table-column
          prop="purchase_parameter"
          align="center"
          label="服务费系数(%)"
          width="100px"
        >
          <template slot-scope="scope">
            {{ scope.row.purchase_parameter }}
          </template>
        </el-table-column>

        <!-- 20240624 二级品类标签 start -->
        <el-table-column
          label="品牌/品种"
          prop="tag"
          minWidth="100px"
          align="center"
        >
          <template slot-scope="scope">
            
            <div class="tag-wrap">
              <div class="tag-box">
                <span
                  v-for="child of scope.row.tags"
                  :key="child.id"
                  class="tag"
                  >{{ child.name }}</span
                >
              </div>
              <span class="icon"
                ><i
                  class="el-icon-edit-outline"
                  @click="onHandleTag(scope.row)"
                ></i
              ></span>
            </div>
          </template>
        </el-table-column>
        <!-- 20240624 二级品类标签 end -->

        <el-table-column
          label="排序"
          prop="listorder"
          width="100px"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="is_audit_gao"
          align="center"
            width="100px"
          label="高碑店集配发品是否需要审核"
        >
          <template slot-scope="scope">
            {{ scope.row.is_audit_gao == 1 ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="is_audit_xiao"
          align="center"
            width="100px"
          label="孝感集配发品是否需要审核"
        >
          <template slot-scope="scope">
            {{ scope.row.is_audit_xiao == 1 ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template slot-scope="scope">
            <el-button type="text" @click="editChild(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="onHandleQrCode(scope.row)"
              >小程序二维码</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 子类标签，弹窗 start -->
    <goods-tag-dialog
      ref="refGoodsTagDialog"
      @on-change="ajaxGetCategories"
    ></goods-tag-dialog>
    <!-- 子类标签，弹窗 end -->

    <!-- 二级品类 -->
    <el-dialog
      :title="childTitle"
      :visible.sync="childDialogVisible"
      v-if="childDialogVisible"
      width="70%"
      class="dialog"
      ref="refChildCategoryDialog"
      center
    >
      <div class="contents">
        <el-form
          :model="childForm"
          :rules="childRules"
          ref="childForm"
          label-width="200px"
          class="demo-ruleForm"
        >
          <el-form-item label="父级品类" prop="pid">
            <div>
              <el-select
                v-model="childForm.pid"
                clearable
                :disabled="childDisabled"
                placeholder="选择父级品类"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="品类名称" prop="name">
            <div>
              <el-input
                v-model="childForm.name"
                placeholder="请输入品类名称"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="运费系数(%)" prop="freight_parameter">
            <div class="inputs">
              <el-input
                v-model="childForm.freight_parameter"
                @blur="freightBlur"
                placeholder="请输入运费"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="服务费系数(%)" prop="purchase_parameter">
            <div class="inputs">
              <el-input
                v-model="childForm.purchase_parameter"
                @blur="purchaseBlur"
                placeholder="请输入服务费"
                clearable
              ></el-input>
            </div>
          </el-form-item>

          <!-- 二级品类增加排序 start -->
          <el-form-item label="排序" prop="listorder">
            <el-input
              v-model="childForm.listorder"
              placeholder="请输入排序值"
              maxlength="5"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="商品详情默认图片" prop="detail_pic">
            <b-file-upload
              key="detail_pic"
              :accepts="['jpg', 'jpeg', 'png']"
              :limit="1"
              v-model="detailPicList"
            ></b-file-upload>
          </el-form-item>

          <el-form-item label="商品详情默认视频" prop="detail_video">
            <b-file-upload
              key="detail_video"
              :accepts="['mp4', 'avi', 'flv', 'mov']"
              :limit="1"
              v-model="detailVideoList"
            ></b-file-upload>
          </el-form-item>
          <!-- 二级品类增加排序 end -->

          <!-- 禁售城市仓 start -->
          <el-form-item label="禁售城市仓" prop="logistic_id">
            <el-select
              class="logistic-select_wrap"
              placeholder="选择禁售城市仓"
              :loading="loadingCityStore"
              v-model="logisticIds"
              @change="onCityStore"
              multiple
              filterable
              clearable
            >
              <el-option
                v-for="item in cityStoreList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 禁售城市仓 end -->
          <el-form-item label="选择招商" prop="buyer_id" v-if="childForm.id&&childForm.is_audit_gao==1">
           
           <el-select
             v-model="childForm.buyer_id"
             clearable
             placeholder="选择选择招商"
           >
             <el-option
               v-for="item in updatecategorylist"
               :key="item.id"
               :label="item.fullname"
               :value="item.id"
             >
             </el-option>
           </el-select>
         
        
       </el-form-item>
          <el-form-item label="高碑店集配发品是否需要审核" prop="is_audit_gao">
            <el-radio-group
              v-model="childForm.is_audit_gao"
              :disabled="is_audit_gao == 1 ? true : false"
              @change="gaochang"
            >
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="孝感集配发品是否需要审核" prop="is_audit_xiao">
            <el-radio-group
              v-model="childForm.is_audit_xiao"
              :disabled="is_audit_xiao == 1 ? true : false"
            >
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="childDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="childConfirm">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import tool from "@/utils/tools/tool";
import {
  getCategoriesList,
  postAddCategories,
  postUpdateCategories,
  postDeleteCategories,
  getCategories,
} from "@/api/generalControl/category";
import { validInteger } from "@/utils/tools/validate";
import throttle from "lodash/throttle";
import BFileUpload from "@/components/business/BFileUpload/index.vue";
import GoodsTagDialog from "./modules/goods-tag-dialog/index.vue";
import { MEMBER_QR_CODE_CONFIG, MINI_APP_PR_CODE_PAGE } from "@/utils/enum";
import { getMiniAppQrCode } from "@/api/export";
import { downLoadFlowBlob } from "@/utils/tools/base";
export default {
  name: "categoryChildren",
  components: { GoodsTagDialog, BFileUpload },
  data() {
    return {
      from: {
        id: "",
        name:'',
        is_audit_xiao: "", //	number	孝感是否审核
        is_audit_gao: "", //	number	高碑店是否审核
        // page: 1,
        // pageSize: 50,
      },
      currentPage: 1,
      total: 0,
      listData: [],
      loading: false,
      loadingCityStore: false, // 城市仓加载loading
      cityStoreList: [], // 城市仓列表
      detailPicList: [], // 图片列表
      detailVideoList: [], // 视频列表
      updatecategorylist:[],
      options:[],
      childForm: {
        pid: "",
        id: "",
        name: "",
        freight_parameter: 100,
        purchase_parameter: 100,
        listorder: "", // 排序值
        detail_pic: "", // 商品详情默认图片
        detail_video: "", // 商品详情默认视频
        logistic_id: "", // 禁售城市仓
        is_audit_xiao: "", //	number	孝感是否审核
        is_audit_gao: "", //	number	高碑店是否审核
        buyer_id:'',//采购id
      },
      is_audit_xiao: "", //	number	孝感是否审核用来判断子集是否能改
      is_audit_gao: "", //	number	高碑店是否审核用来判断子集是否能改
      logisticIds: [], // 禁售城市仓，选中集合
      tagList: [
        {
          id: 1,
          name: "水萝卜",
        },
        {
          id: 2,
          name: "西贡果",
        },
        {
          id: 3,
          name: "果多美",
        },
      ], // 二级品类下的标签
      parentId: "",
      title: "",
      childTitle: "",
      centerDialogVisible: false,
      childDialogVisible: false,
      tableVisible: false,
      childRules: {
        name: [{ required: true, message: "请输入品类名称", trigger: "blur" }],
        freight_parameter: [
          {
            type: "number",
            min: 0,
            message: "最小必须是0",
            trigger: "blur",
          },
        ],
        purchase_parameter: [
          {
            type: "number",
            min: 0,
            message: "最小必须是0",
            trigger: "blur",
          },
        ],
        purchase_parameter: [
          {
            type: "number",
            max: 100,
            message: "请输入0到100的数字",
            trigger: "blur",
          },
        ],
        buyer_id: [{ required: true, message: "请选择招商", trigger: "change" }],
        is_audit_xiao: [
          { required: true, message: "请选择孝感是否审核", trigger: "change" },
        ],
        is_audit_gao: [
          {
            required: true,
            message: "请选择高碑店是否审核",
            trigger: "change",
          },
        ],
        listorder: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value!==0&&!value) {
                callback(new Error("请输入排序值！"));
              }
              if (validInteger(value)) {
                callback(new Error("请输入大于等于0的整数！"));
              }
              callback();
            },
          },
        ],
        // detail_pic: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     validator: (rule, value, callback) => {
        //       if (!this.detailPicList.length) {
        //         callback(new Error("请上传图片！"));
        //       }
        //       callback();
        //     },
        //   },
        // ],
        // detail_video: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     validator: (rule, value, callback) => {
        //       if (!this.detailVideoList.length) {
        //         callback(new Error("请上传视频！"));
        //       }
        //       callback();
        //     },
        //   },
        // ],
        // logistic_id: [
        //   {
        //     required: true,
        //     trigger: ["blur", "change"],
        //     message: "请选择禁售城市仓",
        //   },
        // ],
      },
    };
  },
  watch: {},
  methods: {
    getupdatecategory(e){
      this.$api.general.updatecategory({category_id:e}).then(res=>{
        console.log(res,'1111111111111')
        this.updatecategorylist=res.data;
      })
    },
    gaochang(e){
if(e==0){
  this.childForm.buyer_id='';
}
    },
    gopath(){
this.$router.push({
  path: '/category',
  
})
    },
    addChild() {
      this.childTitle = "新增子类";
      this.childForm = {
        pid: Number(this.$route.query.id),
        id: "",
        name: "",
        freight_parameter: 100,
        purchase_parameter: 100,
        listorder: "",
        detail_pic: "",
        detail_video: "",
        logistic_id: "",
        is_audit_xiao: this.is_audit_xiao,
        is_audit_gao: this.is_audit_gao,
        buyer_id:''
      };
      console.log("ajx addChild", this.childForm);
      /** 重置数据 */
      this.onInitSelectData();
      this.childDisabled = true;
      this.childDialogVisible = true;
    },
    /**
     * 获取城市仓列表
     */
    async ajaxGetCityStoreList() {
      this.loadingCityStore = true;
      const params = {
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        this.cityStoreList = data.data;
        console.log("ajx ajaxGetCityStoreList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      } finally {
        this.loadingCityStore = false;
      }
    },
    /**
     * 城市仓变动
     */
    onCityStore() {
      this.childForm.logistic_id = this.logisticIds.join(",");
    },
    /**
     * 校验运费系数
     */
    freightBlur() {
      console.log(tool);
      console.log(this.childForm.freight_parameter);
      this.childForm.freight_parameter = Number(
        tool.filterUnNumber(this.childForm.freight_parameter)
      );
    },
    /**
     * 校验服务费系数
     */
    purchaseBlur() {
      this.childForm.purchase_parameter = Number(
        tool.filterUnNumber(this.childForm.purchase_parameter)
      );
    },
    /**
     * 获取品类数据
     */
    async getAjaxCategoriesList() {
      this.loading = true;
      try {
        let { data } = await getCategoriesList(this.from);
        this.tableData = data;
        this.options = data;
        this.loading = false;
      } catch (err) {
        console.log("ajax getCategoriesList err", err);
      }
    },
    childConfirm: throttle(function () {
      if(this.detailVideoList.length>0){
        this.childForm.detail_video = this.detailVideoList[0].url;
      }
      if(this.detailPicList.length>0){
        this.childForm.detail_pic = this.detailPicList[0].url;
      }
      this.$refs.childForm.validate(async (valid) => {
        if (valid) {
          /** 参数重组 */
          const {
            pid,
            name,
            freight_parameter,
            purchase_parameter,
            listorder,
            detail_pic,
            detail_video,
            logistic_id,
            is_audit_gao,
            is_audit_xiao,
            buyer_id
          } = this.childForm;
          const params = {
            pid,
            name,
            freight_parameter,
            purchase_parameter,
            listorder: Number(listorder),
            detail_pic,
            detail_video,
            logistic_id,
            is_audit_gao,
            is_audit_xiao,
            buyer_id
          };
          if (this.childTitle == "编辑子类") {
            params.id = this.childForm.id;
            try {
              await postUpdateCategories(params);
              this.childDialogVisible = false;
              let { data } = await getCategories({
                id: Number(this.$route.query.id),
              });
              this.$message({
                type: "success",
                message: "编辑成功",
              });
              this.listData = data.child;
            } catch (err) {
              console.log("ajax postUpdateCategories err", err);
            }
          } else {
            try {
              await postAddCategories(params);
              this.$message({
                type: "success",
                message: "添加成功",
              });
              this.childDialogVisible = false;
              this.tableVisible = false;
              let { data } = await getCategories({
                id: Number(this.$route.query.id),
              });
              this.listData = data.child;
            } catch (err) {
              console.log("ajax postAddCategories err", err);
            }
          }
        }
      });
    }, 500),

    /**
     * 展示二维码
     */
    async onHandleQrCode(row) {
      const { id, pid, name } = row;
      const { cate } = MEMBER_QR_CODE_CONFIG[process.env.NODE_ENV];
      const params = {
        env_version: cate.envCode,
        type: MINI_APP_PR_CODE_PAGE.cate.value,
        firstId: pid,
        secondId: id,
      };
      try {
        const res = await getMiniAppQrCode(params);
        console.log("ajax getMiniAppQrCode", res);
        downLoadFlowBlob(res, res?.filename || name);
      } catch (err) {
        console.log("ajax getMiniAppQrCode err", err);
      }
      /** 前端生成二维码逻辑，已弃 */
      // const { cate } = MEMBER_QR_CODE_CONFIG[process.env.NODE_ENV];
      // const url = cate?.value && `${cate.value}?firstId=${pid}&secondId=${id}`;
      // const params = {
      //   url,
      //   name,
      // };
      // this.$refs.refQrCode.onInitData(params);
      // console.log("🆒 onHandleQrCode", row, params);
    },
    /**
     * 获取二级品类列表
     */
    async ajaxGetCategories() {
      this.hqlist();
    },
    /**
     * 初始化一些数据
     */
    onInitSelectData() {
      this.detailPicList = [];
      this.detailVideoList = [];
      this.logisticIds = [];
    },
    /**
     * 编辑二级品类
     */
    editChild(row) {
      this.onInitSelectData();
      this.childTitle = "编辑子类";
      this.childDisabled = true;
      for (let key in this.childForm) {
        this.childForm[key] = row[key];
      }
      /** 回显信息处理 */
      if (this.childForm?.logistic_id) {
        this.logisticIds = this.childForm?.logistic_id
          .split(",")
          .map((child) => Number(child));
      }
      if (this.childForm.detail_pic) {
        this.detailPicList = this.childForm.detail_pic
          .split(",")
          .map((child) => {
            return {
              name: child,
              url: child,
            };
          });
      }
      if (this.childForm.detail_video) {
        this.detailVideoList = [
          {
            name: this.childForm.detail_video,
            url: this.childForm.detail_video,
          },
        ];
      }
      this.childForm.buyer_id=  row.buyer_id_gao!=0?row.buyer_id_gao:'';
      this.getupdatecategory(row.id);
      this.childDialogVisible = true;
    },
    /**
     * 编辑二级品类 tag
     */
    onHandleTag(row) {
      // this.tagList
      const tags = row.tags;
      const tagList = tags.map((child) => {
        return {
          id: child.id,
          name: child.name,
        };
      });
      const params = {
        cid: row.id,
        is_show: tags?.[0]?.is_show,
        tag_list: tagList || [],
      };
      // console.log("/(ㄒoㄒ)/~~ params", params);
      this.$refs.refGoodsTagDialog.onInitData(params);
    },
    // handleSizeChange(val) {
    //   //   console.log(`每页 ${val} 条`);
    //   this.from.pageSize = val;

    //   this.hqlist();
    // },
    // handleCurrentChange(val) {
    //   this.from.page = val;

    //   this.currentPage = val;

    //   this.hqlist();
    // },
    search(){
        this.hqlist();
    },
    Refresh() {
      Object.assign(this.$data.from, this.$options.data().from);
      this.from.id = Number(this.$route.query.id);
      this.currentPage = 1;
      this.hqlist();
    },
    async hqlist() {
      this.loading = true;
      try {
        let { data } = await getCategories(this.from);
        this.listData = data.child;
        this.is_audit_xiao = data.is_audit_xiao; //	number	孝感是否审核用来判断子集是否能改
        this.is_audit_gao = data.is_audit_gao; //	number	高碑店是否审核用来判断子集是否能改
        this.childForm.is_audit_xiao = data.is_audit_xiao;
        this.childForm.is_audit_gao = data.is_audit_gao;
        console.log(
          "ajax getCategories",
          data,
          data.is_audit_xiao,
          data.is_audit_gao
        );

        this.loading = false;
      } catch (error) {
        console.log("ajax getCategories err", err);
        this.loading = false;
      }
    },
  },
  created() {
    this.getAjaxCategoriesList();
    this.ajaxGetCityStoreList();
    
    this.from.id = Number(this.$route.query.id);
    this.hqlist();
  },
  mounted() {},
};
</script>

<style lang="scss">
.categoryChildren {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;
  .hide {
    .el-upload--picture-card {
      display: none;
    }
  }
  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }
    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;
    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }
    .imgs {
      display: flex;
      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;
        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }
        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }
    .disply {
      display: flex;
      margin-bottom: 22px;
    }
    .mr {
      margin-right: 31px;
    }
    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }
      .el-input {
        width: 302px;
      }
    }
  }
}
.colors {
  color: #fa884c;
}
.times {
  margin-right: 8px;
}
// .el-picker-panel {
//   left: 1245px !important;
// }

.logistic-select_wrap {
  // height: 36px;
  // .el-input {
  //   height: 100%;
  // }
}
/** 标签样式 */
$tag-color: #545454;
$color: #13ae67;
.tag-wrap {
  display: flex;
  justify-content: center;
//   gap: 5px;
  .tag-box {
    // max-width: 200px;
    // height: 35px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    // overflow: hidden;
  }
  .tag {
    // height: 100%;
    line-height: 26px;
    padding: 3px 5px;
    border: 0.5px solid rgba($tag-color, 0.3);
    box-sizing: border-box;
    border-radius: 5px;
    color: $tag-color;
    background-color: rgba($tag-color, 0.1);
  }
  .icon {
    padding: 5px;
    border-radius: 5px;
    color: $color;
    cursor: pointer;
  }
}
</style>